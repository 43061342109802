import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgObjectPipesModule } from 'angular-pipes';
import { NgxWigModule } from 'ngx-wig';

import { AppMaterialModule } from '../app-material/app-material.module';
import { AttachmentEditComponent } from './attachment-edit/attachment-edit.component';
import { AttachmentListComponent } from './attachment-list/attachment-list.component';
import { AttachmentUploadComponent } from './attachment-upload/attachment-upload.component';
import { CourseStatusComponent } from './course-status/course-status.component';
import { EmployeeSelectionDialogComponent } from './employee-selection-dialog/employee-selection-dialog.component';
import { FileSelectionDialogComponent } from './file-selection-dialog/file-selection-dialog.component';
import { LogoUploadComponent } from './logo-upload/logo-upload.component';
import { OrgSelectionDialogComponent } from './org-selection-dialog/org-selection-dialog.component';
import { CurrencyLocalPipe } from './pipes/currency-local.pipe';
import { DefaultPipe } from './pipes/deault.pipe';
import {
  PlannedCourseSelectionDialogComponent,
} from './planned-course-selection-dialog/planned-course-selection-dialog.component';
import {
  PlannedUnitSelectionDialogComponent,
} from './planned-unit-selection-dialog/planned-unit-selection-dialog.component';
import { PromptDialogComponent } from './prompt-dialog/prompt-dialog.component';
import { SendDialogComponent } from './send-dialog/send-dialog.component';
import { OrgFieldComponent } from './org-field/org-field.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterialModule,
    NgObjectPipesModule,
    NgxWigModule
  ],
  declarations: [
    CurrencyLocalPipe,
    OrgSelectionDialogComponent,
    OrgFieldComponent,
    EmployeeSelectionDialogComponent,
    PlannedCourseSelectionDialogComponent,
    PlannedUnitSelectionDialogComponent,
    LogoUploadComponent,
    AttachmentListComponent,
    AttachmentUploadComponent,
    CourseStatusComponent,
    DefaultPipe,
    SendDialogComponent,
    FileSelectionDialogComponent,
    PromptDialogComponent,
    AttachmentEditComponent
  ],
  entryComponents: [
    OrgSelectionDialogComponent,
    EmployeeSelectionDialogComponent,
    PlannedCourseSelectionDialogComponent,
    PlannedUnitSelectionDialogComponent,
    SendDialogComponent,
    FileSelectionDialogComponent,
    PromptDialogComponent,
    AttachmentEditComponent
  ],
  exports: [
    CurrencyLocalPipe,
    OrgSelectionDialogComponent,
    OrgFieldComponent,
    EmployeeSelectionDialogComponent,
    PlannedCourseSelectionDialogComponent,
    PlannedUnitSelectionDialogComponent,
    LogoUploadComponent,
    AttachmentListComponent,
    AttachmentUploadComponent,
    CourseStatusComponent,
    DefaultPipe,
    PromptDialogComponent,
    AttachmentEditComponent
  ]
})
export class SharedModule { }
