import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { PapaParseModule } from 'ngx-papaparse';
import { NgxUiLoaderConfig, NgxUiLoaderHttpModule, NgxUiLoaderModule, SPINNER } from 'ngx-ui-loader';
import { catchError } from 'rxjs/operators';

import { environment } from './../environments/environment';
import { AdminGuard } from './_guards/admin.guard';
import { AuthGuard } from './_guards/auth.guard';
import { TokenInterceptorService } from './_helpers/token-interceptor.service';
import { metaReducers, reducers } from './_reducers';
import { LaravelConfigService } from './_services/laravel';
import { ServicesService } from './_services/services.service';
import { UserService } from './_services/user.service';
import { AppMaterialModule } from './app-material/app-material.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { AlertDialogComponent } from './commons/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from './commons/confirm-dialog/confirm-dialog.component';
import { NotFoundComponent } from './commons/not-found/not-found.component';
import { SharedModule } from './commons/shared.module';
import { ImportDialogComponent } from './home/components/import-dialog/import-dialog.component';
import { ImportOrgsDialogComponent } from './home/components/import-orgs-dialog/import-orgs-dialog.component';
import { LoginModule } from './login/login.module';
import { MomentModule } from 'ngx-moment';

registerLocaleData(localeIt);

export function configServiceFactory(
  laravelConfigService: LaravelConfigService
): Function {
  return () => laravelConfigService
    .load()
    .pipe(
      catchError(error => {
        if (error && error instanceof HttpErrorResponse) {
          if (error.status == 401) {
            return Promise.resolve(null);
          }
        }
        return Promise.reject(error);
      })
    )
    .toPromise();
}

export function initUserFactory(userService: UserService): Function {
  return () => userService.loadCurrentUser();
}

export function initServiceFactory(servicesService: ServicesService): Function {
  return () => servicesService.getAllServices().toPromise();
}

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsType: SPINNER.threeStrings,
  fgsColor: '#FF8633',
  hasProgressBar: false
};

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    ImportDialogComponent,
    ImportOrgsDialogComponent
  ],
  imports: [
    PapaParseModule,
    BrowserModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AppMaterialModule,
    BrowserAnimationsModule,
    LoginModule,
    MomentModule,
    HttpClientModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({
      maxAge: 10
    }),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({
      showForeground: true,
      exclude: [
        `${environment["laravel"]["serverUrl"]}/api/checkVATExisting`,
        `${environment["laravel"]["serverUrl"]}/api/checkIdentifierExists`
      ]
    })
  ],
  providers: [
    LaravelConfigService,
    {
      // Provider for APP_INITIALIZER
      provide: APP_INITIALIZER,
      useFactory: configServiceFactory,
      deps: [LaravelConfigService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initUserFactory,
      deps: [UserService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initServiceFactory,
      deps: [ServicesService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: "it-IT"
    },
    AuthGuard,
    AdminGuard,
    TokenInterceptorService
  ],
  bootstrap: [AppComponent],
  entryComponents: [ConfirmDialogComponent, AlertDialogComponent, ImportDialogComponent, ImportOrgsDialogComponent]
})
export class AppModule { }
