import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Service } from '../_models/service';
import { ListResult } from './../_helpers/listResult.interface';
import { LaravelServicesService } from './laravel/laravel-services.service';

@Injectable({ providedIn: "root" })
export class ServicesService {
  services$: BehaviorSubject<Service[]> = new BehaviorSubject<Service[]>([]);

  constructor(private laravelServicesService: LaravelServicesService) { }

  public addService(service: Service): Observable<Service> {
    return this.laravelServicesService.createService(service.toDTO()).pipe(
      map(dto => {
        return new Service(dto);
      })
    );
  }

  public updateService(service: Service): Observable<Service> {
    return this.laravelServicesService
      .updateService(service.objectId, service.toDTO())
      .pipe(
        map(dto => {
          return new Service(dto);
        })
      );
  }

  public archiveService(service: Service): Observable<Service> {
    return this.laravelServicesService.archiveService(service.objectId).pipe(
      map(() => {
        return service;
      })
    );
  }

  getService(serviceId: number): Observable<Service> {
    return this.laravelServicesService
      .getServiceById(serviceId)
      .pipe(map(dto => new Service(dto)));
  }

  public getAllServices(
    order: string = "name",
    direction: string = "asc",
    include?: string | string[]
  ): Observable<Service[]> {
    return this.getServices(1, 0, order, direction, null, include).pipe(
      map(results => results.data),
      tap(services => this.services$.next(services))
    );
  }

  getServices(
    page: number,
    perPage: number,
    order: string,
    direction: string,
    filter?: string,
    include?: string | string[]
  ): Observable<ListResult<Service>> {
    let includes = typeof include === "string" ? [include] : include;
    return this.laravelServicesService
      .getServices(page, perPage, order, direction, filter, includes)
      .pipe(
        map(response => {
          return {
            data: response.data.map(dto => new Service(dto)),
            total: response.total
          };
        })
      );
  }

  // addService(service: Service): Observable<Service> {
  //   return new Observable<Service>(subscriber => {
  //     this.parseServicesService
  //       .createService(
  //         service.code,
  //         service.name,
  //         service.parentService ? service.parentService.objectId : null,
  //         service.description,
  //         service.expiration,
  //         service.note,
  //         service.detailsUrl
  //       )
  //       .then(
  //         createdService => {
  //           subscriber.next(new Service(createdService));
  //         },
  //         error => {
  //           subscriber.error(error);
  //         }
  //       );
  //   });
  // }

  // updateService(service: Service): Observable<Service> {
  //   return new Observable<Service>(subscriber => {
  //     this.parseServicesService
  //       .updateService(
  //         service.objectId,
  //         service.code,
  //         service.name,
  //         service.parentService ? service.parentService.objectId : null,
  //         service.description,
  //         service.expiration,
  //         service.note,
  //         service.detailsUrl
  //       )
  //       .then(
  //         createdService => {
  //           subscriber.next(new Service(createdService));
  //         },
  //         error => {
  //           subscriber.error(error);
  //         }
  //       );
  //   });
  // }

  // archiveService(service: Service): Observable<Service> {
  //   return new Observable<Service>(subscriber => {
  //     this.parseServicesService.archiveService(service.objectId).then(
  //       () => {
  //         subscriber.next();
  //       },
  //       error => {
  //         subscriber.error(error);
  //       }
  //     );
  //   });
  // }

  // getServicesCount(queryString?: string): Observable<number> {
  //   return new Observable<number>(subscriber => {
  //     this.parseServicesService.getServicesCount(queryString).then(
  //       count => {
  //         subscriber.next(count);
  //       },
  //       error => {
  //         subscriber.error(error);
  //       }
  //     );
  //   });
  // }

  // getServices(
  //   queryString?: string,
  //   start?: number,
  //   limit?: number,
  //   sort?: string,
  //   order?: string
  // ): Observable<Service[]> {
  //   return new Observable<Service[]>(subscriber => {
  //     this.parseServicesService
  //       .getServices(queryString, null, start, limit, sort, order)
  //       .then(
  //         parseServices => {
  //           let results = parseServices.map(parseService => {
  //             return new Service(parseService);
  //           });
  //           subscriber.next(results);
  //         },
  //         error => {
  //           subscriber.error(error);
  //         }
  //       );
  //   });
  // }
}
